import FlowbitePopoverController from "./flowbite/flowbite_popover_controller";

export default class extends FlowbitePopoverController {
  popoverOptions = {
    ...super.popoverOptions,
    placement: "top",
  };

  connect() {
    super.connect();
  }
}
